import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #f5f5f5;
  padding: 1.6rem;
  display: flex;
  margin: auto;

  .c-ls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    opacity: 0.5;

    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
    }

    .c-l {
      img {
        margin: 1.6rem;
        height: 2rem;
      }
    }
  }
`;

export const CLogos = props => (
  <Wrapper>
    <div className="c-ls">
      <div className="c-l">
        <img src={require("./assets/logos/alteo_logo.png")} alt="" />
      </div>
      <div className="c-l">
        <img src={require("./assets/logos/pickit3d_logo.png")} alt="" />
      </div>
      <div className="c-l">
        <img src={require("./assets/logos/tri_logo.png")} alt="" />
      </div>
      <div className="c-l">
        <img src={require("./assets/logos/eyedog_logo.png")} alt="" />
      </div>
      <div className="c-l">
        <img src={require("./assets/logos/thlight_logo.png")} alt="" />
      </div>
      <div className="c-l">
        <img src={require("./assets/logos/secom_taiwan_logo.png")} alt="" />
      </div>
      <div className="c-l">
        <img src={require("./assets/logos/kom_logo.png")} alt="" />
      </div>
      <div className="c-l">
        <img src={require("./assets/logos/tu_logo.png")} alt="" />
      </div>
    </div>
  </Wrapper>
);

import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 1.6rem;
  display: flex;
  max-width: 1000px;
  margin: auto;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }

  .aboutImage {
    /* display: flex;
    flex-direction: column; */

    img {
      width: 100%;
    }
  }

  .note {
    margin-top: 1.6rem;
    font-size: 0.8rem;
    text-align: right;
  }

  .section {
    padding: 0.8rem;
    width: 100%;

    @media screen and (max-width: 500px) {
      padding: 0rem;
    }
  }

  .sectionTitle {
    font-size: 0.8rem;
    color: black;
    letter-spacing: 1px;
    font-weight: 700;
  }

  h2 {
  }

  p {
    color: #4a4a4a;
    font-size: 0.8rem;
  }

  a {
    color: #006fff;
  }
`;

export const About = props => (
  <Wrapper id="about">
    <div className="section">
      <div className="aboutImage">
        <img src={require("./assets/images/about_1.jpg")} alt="" />
        <span className="note">
          Plug and Play Tech Center. San Fransico, October 2018.
        </span>
      </div>
    </div>
    <div className="section">
      <div className="sectionTitle">ABOUT</div>
      <h2>
        We design and build user-friendly software to connect creative minds
        with emerging technologies
      </h2>
      <p>
        Acode(IT) was founded in 2016 in The Hague, the Netherlands. Our passion
        is to build user-friendly software for emerging technologies and
        services. Ease and simplicity are the design guideline we follow – from
        user experience to system architecture, we believe in bringing the great
        solution that is secure, simple to use, and easy to maintain. Till
        today, we are continuously working with innovative partners across
        Europe, Asia, and North America in the sectors of industrial IoT and
        enterprise 2.0., such as asset tracking, robotic control, and production
        monitoring.
      </p>
    </div>
  </Wrapper>
);

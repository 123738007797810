import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 1.6rem;
  max-width: 1000px;
  margin: auto;

  .section {
    padding: 0.8rem;
    @media screen and (max-width: 500px) {
      padding: 0rem;
    }
  }
  .center {
    text-align: center;
  }

  .sectionTitle {
    font-size: 0.8rem;
    color: black;
    letter-spacing: 1px;
    font-weight: 700;
  }

  .s-group {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  .s-l-card {
    width: auto;
    max-width: 650px;
    margin: 0.8rem;
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
    img {
      width: 100%;
    }
  }
  .s-s-card {
    display: flex;
    flex-direction: column;

    .card {
      width: auto;
      margin: 0.8rem;
      max-width: 400px;
      background: white;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2),
        0 4px 10px 0 rgba(0, 0, 0, 0.19);

      @media screen and (max-width: 800px) {
        max-width: 650px;
      }

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .content {
    padding: 1.6rem;
  }

  .line {
    width: 100%;
    border-top: 1px solid #ececec;
    margin: 1.6rem 0%;
  }

  .no-margin {
    margin: 0;
  }

  p {
    color: #4a4a4a;
    font-size: 0.8rem;
  }

  a {
    color: #006fff;
  }
`;

export const Solution = props => (
  <Wrapper id="solution">
    <div className="section center">
      <div className="sectionTitle">SOLUTIONS</div>
      <h2>Powerful solution made simple</h2>
      <p>
        When building a digital solution, we always recommend Cloud solution
        over native software. As it takes significantly less time to deploy,
        distribute and maintain. Moreover, it brings flexibility to the
        integration with other cloud applications and APIs. When necessary, we
        can also assist you in integrating your solution into a private cloud
        for additional security. Currently, our team is actively working on the
        following two sectors: industrial IoT and enterprise cloud solution.
      </p>
    </div>
  </Wrapper>
);

import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #f5f5f5;
  color: #aeaeae;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  a {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .spacer {
    flex-grow: 1;
  }

  .text {
    margin: auto;
  }

  .s-ls {
    display: flex;
    margin: auto;

    .s-l {
      opacity: 0.2;
      :hover {
        opacity: 0.4;
        cursor: pointer;
      }
      img {
        margin: 1rem;
        height: 2rem;
      }
    }
  }
`;

export const Footer = props => (
  <Wrapper>
    <div className="text">© 2019 ACODEIT. All Rights Reserved. </div>
    <div className="text">
      <a className="text">Privacy & Cookies Policy</a>
    </div>
    <div className="spacer" />
    <div className="s-ls">
      <a
        href="https://www.linkedin.com/company/acodeit"
        target="_blank"
        className="s-l"
      >
        <img src={require("./assets/linkedin.svg")} alt="" />
      </a>
      <a
        href="https://www.facebook.com/acodeit"
        target="_blank"
        className="s-l"
      >
        <img src={require("./assets/facebook.svg")} alt="" />
      </a>
      <a href="https://twitter.com/acodeit" target="_blank" className="s-l">
        <img src={require("./assets/twitter.svg")} alt="" />
      </a>
    </div>
  </Wrapper>
);

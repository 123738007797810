import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: black;
  padding: 1.6rem;

  .section {
    padding: 1.6rem;
    margin: auto;
    @media screen and (max-width: 500px) {
      padding: 0rem;
    }
  }

  .center {
    text-align: center;
  }
  .sectionTitle {
    font-size: 0.8rem;
    color: white;
    letter-spacing: 1px;
    font-weight: 700;
  }

  p {
    color: white;
    font-size: 0.8rem;
  }

  a {
    color: #006fff;
  }
`;

export const Contact = props => (
  <Wrapper id="contact">
    <div className="section center">
      <div className="sectionTitle">CONTACT</div>
      <p>
        Having a project in mind? Get in touch with us (via{" "}
        <a href="mailto:contact@acodeit.com">contact@acodeit.com</a>) to make an
        development estimation for your project.
      </p>
    </div>
  </Wrapper>
);

import * as _ from "lodash";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Icon,
  Collapse
} from "@material-ui/core";

const Line = styled.div`
  width: 100%;
  border-top: 1px solid #ececec;
  margin: 1.6rem 0%;
`;

const SectionTitle = styled.div`
  font-size: 0.8rem;
  color: black;
  letter-spacing: 1px;
  font-weight: 700;
`;

const Wrapper = styled.div`
  padding: 1.6rem;
  max-width: 1000px;
  margin: 0 auto 3rem auto;

  display: flex;
  justify-content: space-between;

  .card {
    width: 300px;

    .collapse {
      display: none;
    }
  }

  @media (min-width: 1px) and (max-width: 1000px) {
    flex-direction: column;

    .card {
      width: 100%;

      .collapse {
        display: block;
      }

      :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
`;

const Closer = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`;

const data = [
  {
    title: "Industrial IoT (IIoT)",
    img: require("./assets/images/iot_2.jpeg"),
    content: (
      <React.Fragment>
        <p>
          The industrial IoT consists of a wide range of data-driven
          applications, including smart factory, automation, asset tracking or
          monitoring with smart sensors in combination with modern cloud
          computing. Its connectivity and real-time information bring greater
          efficiency and transparency in many aspects of today’s manufacturing
          operation. We work with clients in Europe and Asia to design and
          deploy IIoT solution for various use cases and locations, including
          robotic control, human and asset tracking, virtual fence network and
          production monitoring. We work closely with our manufacturing partner
          in Taiwan to ensure the quality of the smart sensors meets with
          expectation.
        </p>
        <Line />
        <SectionTitle>keywords</SectionTitle>
        <p>
          Asset tracking, industrial safety, security, indoor positioning,
          beacon, robotic control
        </p>
      </React.Fragment>
    )
  },
  {
    title: "Enterprise 2.0",
    img: require("./assets/images/ent2.0_1.jpeg"),
    imgFocus: "50% 0%",
    content: (
      <React.Fragment>
        <p>
          Differing from the traditional static enterprise resources management
          (ERM) system, Enterprise 2.0 integrates web 2.0 technologies into
          organisations, where highlights the collaboration and engagement
          between multiple stakeholders, including employees, consumers, and
          managers. Such integration aims to create better efficiency and
          productivity, moreover to stimulate the creation of innovation in an
          open environment.
        </p>
        <p>
          We helped our clients to develop various enterprise solutions,
          including HR system, conference scheduling, simplified ERM system and
          interactive client communication portal.
        </p>
        <Line />
        <SectionTitle>keywords</SectionTitle>
        <p>ERM, CRM, Cloud solution, web application</p>
      </React.Fragment>
    )
  },
  {
    title: "Other custom apps",
    img: require("./assets/images/other_1.jpeg"),
    content: (
      <React.Fragment>
        <p>
          We can also help you with small-scaled websites that are responsive
          (accessible for desktop and mobile device). There is also an option to
          have the content management system where you can update your site
          easily. Get in touch with us to schedule an appointment.
        </p>
        <Line />
        <SectionTitle>keywords</SectionTitle>
        <p>Responsive website, content management system, technical support</p>
      </React.Fragment>
    )
  }
];

export function Solution2(props) {
  const [idx, setIdx] = useState(undefined);
  const isMobile = useMedia(["(max-width: 1000px)"], [true], false);
  const currentEntry = data[idx];

  return (
    <Wrapper>
      {!isMobile && (
        <Dialog
          maxWidth="md"
          fullWidth
          onClose={() => {
            setIdx(undefined);
          }}
          open={idx !== undefined}
        >
          <Closer>
            <IconButton onClick={() => setIdx(undefined)}>
              <Icon>close</Icon>
            </IconButton>
          </Closer>
          {currentEntry && (
            <React.Fragment>
              <DialogTitle id="simple-dialog-title">
                {currentEntry.title}
              </DialogTitle>
              <div
                style={{
                  display: "block",
                  maxWidth: "100%",
                  height: 400,
                  minHeight: 400,
                  backgroundImage: `url(${currentEntry.img}?${+new Date()})`,
                  backgroundSize: "cover",
                  backgroundPosition: currentEntry.imgFocus || "center center"
                }}
              />
              <div style={{ padding: "1rem" }}>{currentEntry.content}</div>
            </React.Fragment>
          )}
        </Dialog>
      )}

      {data.map((x, elIdx) => (
        <Solution2Card
          expanded={idx === elIdx}
          onClick={() => setIdx(elIdx === idx ? undefined : elIdx)}
          {...x}
        />
      ))}
    </Wrapper>
  );
}

export function Solution2Card({
  img,
  title,
  content,
  onClick,
  expanded,
  imgFocus
}) {
  return (
    <Card className="card" onClick={onClick}>
      <CardActionArea>
        <CardMedia
          style={{
            minHeight: 200,
            backgroundPosition: imgFocus || "center center"
          }}
          image={img}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={onClick}>
          Learn More
        </Button>
        <IconButton
          style={{ marginLeft: "auto" }}
          onClick={e => {
            onClick();
            e.preventDefault();
            e.stopPropagation();
          }}
          aria-label="Show more"
        >
          <Icon>expand_more</Icon>
        </IconButton>
      </CardActions>

      <Collapse className="collapse" in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{content}</CardContent>
      </Collapse>
    </Card>
  );
}

export default function useMedia(queries, values, defaultValue) {
  const match = () =>
    values[_.findIndex(queries, q => matchMedia(q).matches)] || defaultValue;

  const [value, set] = useState(match);

  useEffect(() => {
    const handler = () => {
      console.log(match());
      set(match);
    };
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, []);
  return value;
}

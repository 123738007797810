import React from "react";
import { render } from "react-dom";
import "./index.scss";

import CssBaseline from "@material-ui/core/CssBaseline";
import { Navigation } from "./scr/Navigation";
import { Landing } from "./scr/Landing";
import { About } from "./scr/About";
import { Solution } from "./scr/Solution";
import { Promotion } from "./scr/Promotion";
import { Contact } from "./scr/Contact";
import { Footer } from "./scr/Footer";
import { CLogos } from "./scr/CLogos";
import { Process } from "./scr/Process";
import { Solution2 } from "./scr/Solution2";

const App = () => {
  return (
    <div>
      <section>
        <CssBaseline />
        <Navigation />
        <Landing />
        <About />
        <CLogos />
        <Solution />

        <Solution2 />

        <Promotion />
        <Process />
        <Contact />
        <Footer />
      </section>
    </div>
  );
};

render(<App />, document.getElementById("root"));

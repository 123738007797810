import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-image: linear-gradient(to bottom right, #3023ae, #c86dd7);
  padding: 1.6rem;
  display: flex;
  color: white;

  .section {
    padding: 0.8rem;
    margin: auto;
    max-width: 1000px;
    width: 100%;
    img {
      max-height: 20rem;
      display: block;
      margin: auto;
    }

    @media screen and (max-width: 500px) {
      padding: 0rem;
    }
  }

  .flex {
    display: flex;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  .sectionTitle {
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 700;
  }

  p {
    font-size: 0.8rem;
  }

  a {
    color: white;
    font-size: 0.8rem;
    padding: 0.8rem;
    letter-spacing: 1px;
    font-weight: 700;
    border: solid 1px;
    border-radius: 5rem;
    text-decoration: none;
    display: inline-block;
    margin: 0.8rem 0rem;
    :hover {
      color: #3023ae;
      background: white;
    }
  }
`;

export const Promotion = props => (
  <Wrapper>
    <div className="section flex">
      <div className="section">
        <div className="sectionTitle">ENTERPRISE 2.0 – LEGAL TECH</div>
        <h2>
          TailorFlow: Grow your legal practice with client-centered solutions
        </h2>
        <p>
          Setup your company client communication channel instantly. Track case
          process, costs and files with clients at one place.
        </p>

        <a href="https://tailorflow.com/">TRY IT FREE</a>
      </div>
      <div className="section">
        <img src={require("./assets/images/tw_1.png")} alt="" />
      </div>
    </div>
  </Wrapper>
);

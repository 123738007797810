import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 1.6rem;
  max-width: 1000px;
  margin: auto;

  .section {
    padding: 1.6rem;
    margin: auto;
    @media screen and (max-width: 500px) {
      padding: 0rem;
    }
  }

  .center {
    text-align: center;
  }

  .max-width {
    width: 800px;
    margin: auto;
  }
  .sectionTitle {
    font-size: 0.8rem;
    color: black;
    letter-spacing: 1px;
    font-weight: 700;
  }

  .t-group {
    display: flex;
    border-bottom: 1px solid #a9a9a9;
    justify-content: space-between;
    margin-top: 1.6rem;
  }

  .tab {
    padding: 0.8rem;
    flex-grow: 1;
    font-size: 0.8rem;
    color: #a9a9a9;
    cursor: pointer;
    :hover {
      color: #808080;
      border-bottom: 1px solid #c8c8c8;
    }
  }

  .selected {
    border-bottom: 1px solid #404040;
    color: #404040;
  }

  p {
    color: #4a4a4a;
    font-size: 0.8rem;
    padding: 0.8rem;
  }
`;

const data = [
  {
    tabTitle: "STEP 1 MEASURE",
    content: (
      <div>
        We start with the requirement analysis and draft a solution landscape
        where the feasibility and advantages of several possibilities are shown.
        Among these options, we will help you to determine which solution fits
        your needs best. The activities of this stage include: IT consulting,
        Requirement analysis, Define Solution landscape.
      </div>
    )
  },
  {
    tabTitle: "STEP 2 DEFINE",
    content: (
      <div>
        Once the scope has been defined, we will start sketching in combination
        with prototyping. It is important to have a pre-validation before
        entering the building stage to make sure the outcome meets the
        expectations. The activities of this stage include: Discussion &
        workshop, Wireframing & prototyping, Define user experience, Feasibility
        test.
      </div>
    )
  },
  {
    tabTitle: "STEP 3 BUILD",
    content: (
      <div>
        As your participation is vital for a software's success, we want to have
        your input in the development process. Therefore, you will receive a
        test account, where you can try the product as early as possible to
        ensure a smooth transition before the final delivery. The activities of
        this stage include: Early software access(sandboxing), Cloud hosting,
        Deployment & testing.
      </div>
    )
  },
  {
    tabTitle: "STEP 4 SUPPORT",
    content: (
      <div>
        Every project includes technical support after its delivery. We will
        guide you through the details and discuss possible rooms for extensions
        and modifications. Your feedback is important to us to keep the product
        in its full performance. The activities of this stage include:
        Service-Layer-Agreement, Continuous maintenance.
      </div>
    )
  }
];

export const Process = props => {
  const [index, setIndex] = useState(1);
  return (
    <Wrapper>
      <div className="section">
        <div className="sectionTitle center">HOW WE WORK</div>
        <div className="t-group center">
          {data.map((entry, entryIndex) => (
            <div
              className={entryIndex !== index ? "tab" : "tab selected"}
              onClick={() => setIndex(entryIndex)}
            >
              {entry.tabTitle}
            </div>
          ))}
        </div>
        <div>
          <p>{data[index].content}</p>
        </div>
      </div>
    </Wrapper>
  );
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatToTimeZone } from "date-fns-timezone";
import { listTimeZones } from "timezone-support";

const Wrapper = styled.div`
  background: black;
  color: white;
  height: auto;
  background-image: url(${require("./assets/images/bg_2.jpg")});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 2.4rem;

  .innerContent {
    margin: auto;
    max-width: 1000px;
  }

  h1 {
    font-size: 4rem;
    max-width: 1000px;

    @media screen and (max-width: 800px) {
      font-size: 2.4rem;
    }
  }

  button {
    background: transparent;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 0.8rem;
    color: white;
    border-radius: 5rem;
  }

  .section {
    padding: 1.6rem;
  }

  .c-group {
    display: flex;
  }

  .clock {
    display: flex;
    flex-direction: column;
    margin-right: 1.6rem;

    .place {
      font-size: 0.8rem;
    }

    .time {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 800px) {
  }
`;

export const Landing = props => (
  <Wrapper id="landing">
    <div className="innerContent">
      <div className="section">
        <h1>
          We create software to drive productivity, increase efficiency in the
          workplace of human and machine
        </h1>
      </div>
      <div className="section">
        <div className="c-group">
          <Clock region="AMS" tz="Europe/Amsterdam" />
          <Clock region="TPE" tz="Asia/Taipei" />
          <Clock region="SFO" tz="America/Los_Angeles" />
        </div>
      </div>
    </div>
  </Wrapper>
);

console.log(listTimeZones());

function Clock(props) {
  const [dateString, setDateString] = useState("00:00");
  const [tick, setTick] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick(!tick);
      const tmp = formatToTimeZone(new Date(), "HH:mm", {
        timeZone: props.tz
      });
      setDateString(tmp);
    }, 1000);
    return function() {
      clearInterval(interval);
    };
  });

  const [hh, mm] = dateString.split(":");

  return (
    <div className="clock">
      <span className="place">{props.region}</span>
      <span className="time">
        <span>{hh}</span>
        <span
          style={{
            opacity: tick ? 0 : 1,
            transition: "all 0.4s"
          }}
        >
          :
        </span>
        <span>{mm}</span>
      </span>
    </div>
  );
}

import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${props => (props.isBlack ? "black" : "transparent")};
  display: flex;
  position: fixed;
  transition: 0.3s;
  width: 100%;
  z-index: 100;

  .logo {
    padding: 0.8rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    color: #d5d5d5;
    width: 100%;
  }
  img {
    margin: 0.8rem;
    height: 1.6 rem;
    :hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
  .spacer {
    flex-grow: 1;
  }

  .menuIcon {
    display: none;
    margin: auto;

    img {
      height: 0.8rem;
    }
  }

  .menu {
    display: flex;

    a {
      padding: 1.6rem;
      margin: auto;
      font-size: 0.8rem;
      color: white;
      letter-spacing: 1px;
      font-weight: 700;
      display: inline-block;
      text-decoration: none;

      :hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .sub-text {
      display: none;
    }

    .menuIcon {
      display: inherit;
      position: absolute;
      top: 1rem;
      right: 1.8rem;
    }
    .menu {
      flex-direction: column;
      background: black;
      width: 100vw;
      height: 100vh;
      position: fixed;
      padding: 2rem;
      transform: translateX(${props => (props.isMenuVisible ? "0%" : "100%")});
      transition: all 0.3s;
    }
  }
`;

const scrollToView = idName => e => {
  history.pushState(null, null, "#" + idName);
  e.preventDefault();
  const target = document.getElementById(idName);
  const y = target.getBoundingClientRect().top + window.scrollY;
  window.scroll({
    top: y - 100,
    behavior: "smooth"
  });
};

export class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlack: false,
      isMenuVisible: false
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll.bind(this));
  }
  listenToScroll() {
    this.setState({ isBlack: document.documentElement.scrollTop > 0 });
  }

  render() {
    return (
      <Wrapper
        isBlack={this.state.isBlack}
        isMenuVisible={this.state.isMenuVisible}
      >
        <div className="logo">
          <a href="#landing" onClick={scrollToView("landing")}>
            <img src={require("./assets/acode_logo.svg")} alt="" />
          </a>
          <div className="sub-text">Software Design & Development</div>
        </div>
        <div className="spacer" />
        <div
          className="menuIcon"
          onClick={() => {
            this.setState({ isMenuVisible: !this.state.isMenuVisible });
          }}
        >
          <img src={require("./assets/menu_white.svg")} alt="" />
        </div>
        <div className="menu">
          <div
            className="menuIcon"
            onClick={() => {
              this.setState({ isMenuVisible: !this.state.isMenuVisible });
            }}
          >
            <img src={require("./assets/close_white.svg")} alt="" />
          </div>
          <a href="#about" onClick={scrollToView("about")}>
            ABOUT
          </a>
          <a href="#solution" onClick={scrollToView("solution")}>
            SOLUTIONS
          </a>
          <a href="#contact" onClick={scrollToView("contact")}>
            CONTACT
          </a>
        </div>
      </Wrapper>
    );
  }
}
